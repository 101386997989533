@import "scss/global";

.history-filter {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .wfp--content-switcher-btn {
    min-width: 75px;
    justify-content: center;
  }
}
