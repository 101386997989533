@import "scss/global";

.sidebar-header {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 5px;
  padding: $spacing-md;
  background: $ui-02;
  border-bottom: 1px solid darken($ui-04, 3);

  button {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 5px;
  }
}
