@import "scss/global";

@include breakpointMax(bp--md--major) {
  .commodities-show {
    .commodities-show__quantity {
      font-size: 1.5rem;
    }
    thead {
      display: none;
    }
    table,
    tbody,
    td {
      display: block;
      width: 100%;
    }
    td {
      border: none;
    }
    thead {
      display: none;
    }
    table,
    tbody,
    td {
      display: block;
      width: 100%;
    }
    tr {
      padding: $spacing-xs;
      //margin-bottom: 1rem;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto;
      grid-template-areas: "left right";
      //margin-bottom: 1rem;
      &:last-child {
        border: none;
        margin: 0;
      }
    }

    .commodities-show__quantity {
      grid-area: right;
      text-align: right;
    }

    td {
      grid-column-start: 1;
      border: none;
    }
    &__quantity {
      font-size: 3rem;
    }
    .mobile-title {
      display: block;
      font-weight: 600;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
      font-size: 0.8rem;
      &:after {
        content: ":";
      }
    }
  }
}

@include breakpoint(bp--md--major) {
  .no-shrink {
    white-space: nowrap;
  }

  .mobile-title {
    display: none;
  }
}

.commodities-show {
  .waybills,
  .purchases {
    display: flex;
    flex-direction: column;

    > span {
      width: fit-content;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
